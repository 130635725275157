import React, { useEffect, useState } from "react";
import { Box,Typography} from '@mui/material';

import { styled } from "@mui/system";

import confetti from "canvas-confetti";


const Flashcard = ({ card, handleNext, handleEnd, handleRemove }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [flipping, setFlipping] = useState(false);


  useEffect(() => {
    
  }, [])

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    const correct = option === card.answer;
    console.log(option)
    if(correct){
        confetti({
            angle: 90,
            particleCount: 100,
            spread: 50,
            origin: { y: 0.8 }
          });
    }
    setIsCorrect(correct);
    setShowResult(true);
  };

  const handleNextClick = () => {
    setFlipping(true);

    setTimeout(() => {
      setSelectedOption("");
      setShowResult(false);
      setIsCorrect(false);
      setFlipping(false);
      handleNext();
    }, 600);


  };
  const handleRemoveClick = () => {
    setFlipping(true);
    setTimeout(async() => {
      setSelectedOption("")
      setShowResult(false)
      setIsCorrect(false)
      setFlipping(false)
      await handleRemove()
      handleNext()
    }, 600);
  }

  const endButton = () => {
    handleEnd()
  }


  const [isCorrect, setIsCorrect] = useState(false);
  

  const colors = {
    gradientBackground: "#212121", // Dark blue to soft purple
    primaryBox: "#212121", // Dark blue shade for the curved box
    button: "#ffe600", // Purple shade for the button
    buttonHover: "#ffe600", // Darker purple for hover effect
    textPrimary: "#EEEEEE", // White text color
  };
  const Typographies = styled(Typography)({
    fontFamily: "Rubik"
  })
  const ReportBox = styled(Box)({
    backgroundColor: colors.primaryBox,
    fontFamily: "Rubik",
    borderRadius: "20px",
    padding: "20px",
    marginBottom: "20px",
    color: colors.textPrimary,
    textAlign: "center",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    border: "0.2px #303030 solid"
  });

  return (
    <Box>
      
        <ReportBox>
              <Typographies variant="h5" sx={{ marginBottom: "10px", width: '320px' }}>
                  <span style={{ fontWeight: 'bold', color: '#ffe8b9', textShadow: "rgba(255, 247, 0, 0.4) 1px 3px 20px" }}>{card.word}</span>
                  <p style={{ fontSize: '16px', color: '#838383' }}>معنی این کلمه چیه؟</p>
              </Typographies>

          </ReportBox>

          <div className={`flashcard ${flipping ? "flipping-out" : "flipping-in"}`}>
              
              <div className="options">
                  {card.options.map((option, index) => (
                      <button
                          key={index}
                          onClick={() => handleOptionClick(option)}
                          disabled={showResult}
                          className={`answerBTN ${
                            showResult
                                ? option === card.answer
                                    ? "correct"
                                    : option === selectedOption
                                    ? "incorrect"
                                    : ""
                                : ""
                        }`}
                      >

                          {option}
                      </button>
                  ))}
              </div>
             
                <button disabled={!showResult} className="nextBtn" onClick={handleNextClick}>کلمه بعدی 🫰</button>
                <button disabled={!showResult} className="nextBtn" onClick={handleRemoveClick}>یادگرفتم، حذفش کن ✅</button>
                <button disabled={!showResult} className="nextBtn" onClick={handleEnd}>پایان تمرین 💔</button>
              
        {/* <div className="result">
          {showResult && (
            <span>
              {isCorrect ? (
                <span>درست است</span>
              ) : (
                <span>اشتباه بود، پاسخ صحیح {card.answer} است!</span>
              )}
            </span>
          )}
        </div> */}
      </div>
    </Box>
  );
};

export default Flashcard;
