import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import '../index.css'
import { styled, keyframes } from "@mui/system";
import moment from 'moment-jalaali'
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';

import Flashcard from './Flashcard';
import EarnPage from './Earnpage';
import axios from 'axios';

function LeitnerApp() {


  const colors = {
    gradientBackground: "#212121", // Dark blue to soft purple
    primaryBox: "#212121", // Dark blue shade for the curved box
    button: "#ffe600", // Purple shade for the button
    buttonHover: "#ffe600", // Darker purple for hover effect
    textPrimary: "#EEEEEE", // White text color
  };
  const [date, setDate] = useState('Loading...')
  const [errorType, setErrorType] = useState('ErrorType')
  const [page, setPage] = useState('index')
  const [isNewUser, setNewUser] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [isLowBox, setLowBoxError] = useState(false)
  const [start, setStart] = useState(false)
  const [isIndex, setIsIndex] = useState(true)
  const [earnpage, setEarnPageActive] = useState(false)
  const [earnTasks, setEarnTasks] = useState([{
    _id: '',
    name: '',
    value: 0,
    completed: true
  }])
  
  const [amount, setAmount] = useState(0)
  const [isEndAmount, setIsEndAmount] = useState(false)
  const [learnedCount, setLearned] = useState(0)
  const [unLearnedCount, setUnLearned] = useState(0)
  const [userId, setUserId] = useState(0)
  const [isError, setError] = useState(false)
  const [currentWord, setWord] = useState({
    id: '',
    word: '',
    options: [],
    answer: ''
  })
  const isDev = false
  let devUrl = '/'
  let errorText = 'error'
  if(isDev){
    devUrl = 'http://localhost:6093/'
  }
  const handleStart = async () => {
    await getNextWord()
    setPage('training')
    setStart(true)
  }

  const handleEarn = async() => {
    setLoading(true)
    await getAwards()
  }

  const getAwards = async() => {
    const getrewardsUrl = `${devUrl}web/rewardlist`
        axios.post(getrewardsUrl, {userId: userId})
            .then(res => {
                console.log(res.data)
                setEarnTasks(res.data)
                setLoading(false)
            }).catch(err => {

            })
  }

  const backToMain = () => {
    setIsIndex(true)
  }
  const [currentCardIndex, setCurrentCardIndex] = useState(0)
  let tg 

  moment.loadPersian({ dialect: 'persian-modern', usePersianDigits: true })
  useEffect(() => {

    // Production Mode
    let username = 0
    if(!isDev){
      tg = window.Telegram.WebApp
      tg.ready()
      tg.expand()
      username = tg.initDataUnsafe?.user.id
    }else{
      username = 933130569
    }

    const todayShamsi = moment().format('jD jMMMM jYYYY');
    console.log(todayShamsi)
    setDate(todayShamsi)
    setUserId(username)

    const maxRetries = 5;  // Set a maximum number of retries
    let retryCount = 0
    const fetchUserId = async () => {
      if(isDev){

        const username = 193183447
      }else{

        const username = await tg.initDataUnsafe?.user?.id;
      }

      // Dev Mode


      if (username) {
        setUserId(username);
      } else if (retryCount < maxRetries) {
        console.warn('User ID not available, retrying...');
        retryCount = retryCount + 1;
        setTimeout(fetchUserId, 500);  // Retry after 500ms
      } else {
        setErrorType('not response')
        // errorText = 'not response'
        setError(true)
        console.error('Failed to retrieve User ID after multiple attempts');
      }
    };

    fetchUserId();




    // UnComment lines bellow when just ready to deploy!



  }, [])




  useEffect(() => {
    // setLoading(true)
    if (userId != 0) {
      // console.log(`${devUrl}web/getdata`)
      const getdataurl = `${devUrl}web/getdata`
      // console.log(getdataurl)
      axios.post(getdataurl, { userId: userId })
        .then((res) => {
          if (res.data.message === 'ok') {
            setAmount(res.data.leitnerAmount)
            setLearned(res.data.learnedWord)
            setNewUser(false)
            setUnLearned(res.data.unLearnedWord)
            if (res.data.unLearnedWord < 5) {
              setLowBoxError(true)
            }
            setLoading(false)

          } else if (res.data.message === 'notfound') {
            setLoading(false)
            setNewUser(true)
          }

        })
        .catch((err) => {
          console.log(err.message)
          setErrorType(err.message)
          errorText = 'err'
          setError(true)
          setLoading(false)
          console.error('Error fetching user data:', err);
        });

    }
  }, [userId])


  // useEffect(() => {
  //   let count = Number(unLearnedCount)
  //   if(count <= 5){
  //     setLowBoxError(true)
  //   }else{
  //     setLowBoxError(false)
  //   }
  // }, [unLearnedCount])



  function getNextWord() {
    setLoading(true)
    const nextwordUrl = `${devUrl}web/nextword`
    axios.post(nextwordUrl, { userId: userId })
      .then((res) => {
        setAmount(res.data.leitnerAmount)
        if (res.data.leitnerAmount <= 0) {
          setPage('index')
          setIsEndAmount(true)
          
        }else{

          setWord({
            id: res.data.word.id,
            word: res.data.word.text,
            options: res.data.word.options,
            answer: res.data.word.correctMeaning
          })
        }
        setLoading(false)
      })
      .catch((err) => {
        setError(true)
        console.error('Error fetching user data:', err);
      })
  }
  const handleNext = async () => {
    await getNextWord()
  }

  const showEarnPage = async() =>{
    setLoading(true)
    await getAwards()
    setStart(false)
    setPage('earn')
    // setEarnPageActive(true)
  }

  const backHome = () => {
    setLoading(true)
    const getdataurl = `${devUrl}web/getdata`
    axios.post(getdataurl, { userId: userId })
    .then((res) => {
      if (res.data.message === 'ok') {
        setAmount(res.data.leitnerAmount)
        setLearned(res.data.learnedWord)
        setNewUser(false)
        setUnLearned(res.data.unLearnedWord)
        if (res.data.unLearnedWord < 5) {
          setLowBoxError(true)
        }else{
          setLowBoxError(false)
          
        }
        if(res.data.leitnerAmount <= 0){
          setIsEndAmount(true)
        }else{
          setIsEndAmount(false)
        }
        setLoading(false)

      } else if (res.data.message === 'notfound') {
        setLoading(false)
        setNewUser(true)
      }

    })
    .catch((err) => {
      console.log(err.message)
      setErrorType(err.message)
      errorText = 'err'
      setError(true)
      setLoading(false)
      console.error('Error fetching user data:', err);
    });


    setStart(false);
    setPage('index')
  }
 
  const handleEnd = () => {
    setStart(false);
    setPage('index')
  }

  const handleRemove = () => {
    setLoading(true)
    const removeUrl = `${devUrl}web/remove`
    axios.post(removeUrl, { userId: userId, word: currentWord.word, wordId: currentWord.id })
      .then(res => {

      })
      .catch(err => {

      })
  }

  // Glowing animation keyframes
  const shine = keyframes`
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
`;



  const GlowingButton = styled(Button)({
    background: "linear-gradient(90deg, #ffe600, #ffa217, #ffe600)",
    backgroundSize: "200% 200%",
    borderRadius: "20px",
    color: '#000',
    fontFamily: "Rubik",
    padding: "10px 30px",
    textTransform: "none",
    fontSize: "18px",
    fontWeight: "bold",
    width: '100%',
    border: "1px solid black",
    boxShadow: " 0px 10px 50px -20px #efb021, 0px 30px 60px -30px #efb021",
    animation: `${shine} 2s linear infinite`,
    "&:hover": {
      background: "linear-gradient(90deg, #ffe600, #ffa217, #ffe600)",
    },

  });

  const GemButton = styled(Button)({
    background: "#363535",
    marginTop: "10px",
    borderRadius: "20px",
    color: '#fff',
    fontFamily: "Rubik",
    padding: "10px 30px",
    textTransform: "none",
    fontSize: "15px",
    fontWeight: "bold",
    width: '100%',
    border: "1px solid #767676",

  })
  // Styled curved box for reports
  const ReportBox = styled(Box)({
    backgroundColor: colors.primaryBox,
    fontFamily: "Rubik",
    borderRadius: "20px",
    padding: "20px",
    marginBottom: "20px",
    color: colors.textPrimary,
    textAlign: "center",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    border: "0.2px #303030 solid"
  });

  const Typographies = styled(Typography)({
    fontFamily: "Rubik"
  })

  // Styled curved button for starting learning
  const StartButton = styled(Button)({
    backgroundColor: colors.button,
    fontFamily: "Rubik",
    borderRadius: "30px",
    color: colors.textPrimary,
    padding: "10px 30px",
    textTransform: "none",
    fontSize: "18px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: colors.buttonHover,
    },
  });







  if (isLoading) {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          background: colors.gradientBackground,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }} >
        <CircularProgress />

      </Box>
    )
  }

  if (isError) {
    return (
      <>
      <Box
        sx={{
          minHeight: "100vh",
          background: colors.gradientBackground,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }} >
        <ReportBox>
          <Typographies variant="h5" sx={{ marginBottom: "10px", width: '320px', direction: 'rtl' }}>
            <span>یک خطا رخ داده است</span>
            <p style={{ fontSize: '11px', color: '#838383' }}> 
            {errorType}
            </p>
           
          </Typographies>
          <p>{userId}</p>
        </ReportBox>

      </Box>
    </>
      
    )
  }

  if (isLowBox) {
    return (
      <>
        <Box
          sx={{
            minHeight: "100vh",
            background: colors.gradientBackground,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }} >
          <ReportBox>
            <Typographies variant="h5" sx={{ marginBottom: "10px", width: '320px', direction: 'rtl' }}>
              <span>تعداد کلمات شما کم است</span>
              <p style={{ fontSize: '11px', color: '#838383' }}> تعداد کلمات موجود در جعبه لایتنر شما کم است. به ربات برگردید و کلمات بیشتری را به جعبه اضافه کنید تا تمرین را شروع کنیم. حداقل 6 کلمه باید به جعبه اضافه کنید.
              </p>
              <p>
                {unLearnedCount}
              </p>
            </Typographies>
            <p>{userId}</p>
          </ReportBox>

        </Box>
      </>
    )

  }

  if (isNewUser) {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          background: colors.gradientBackground,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }} >
        <ReportBox>
          <Typographies variant="h5" sx={{ marginBottom: "10px", width: '320px' }}>
            <span> شما هنوز ربات وردینا رو استارت نزدید!</span>
            <p style={{ fontSize: '11px', color: '#838383' }}>          لطفا به ربات برگردید و دکمه Start رو بزنید. یک بسته کلمه انتخاب کنید و کلمات رو تمرین کنید. کلماتی که بلد نیستید رو به لایتنر اضافه کنید و به اینجا برگردید.
            </p>
          </Typographies>
          <p>{userId}</p>
        </ReportBox>

      </Box>
    )
  }

  return (


    <>

      <Box
        sx={{
          // minHeight: "100vh",
          background: colors.gradientBackground,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }} >
        {page === 'index' ? (
          <img
            src={`/book.webp`}
            className='bookMotion'
          />

        ) :page === 'earn'? (
          <img
            src={`/money.webp`}
            className='bookMotion'
          />
        ) : (
          <></>
        )}
        <div style={{ color: '#838383', display: 'flex', direction: 'rtl', marginBottom: '10px', fontSize: '14px', textAlign: 'left', width: '100%' }}>
          <span style={{ color: '#ffe600', boxShadow: " 0px 10px 50px -20px #efb021, 0px 30px 60px -30px #efb021" }}><OfflineBoltOutlinedIcon></OfflineBoltOutlinedIcon></span>
          <span style={{ width: '50%', color: '#ffe600', textAlign: 'right', padding: '4px' }}> سکه‌های من: {amount}</span>
          <span style={{ width: '50%' }}>امروز {date}</span>

        </div>
        {page == 'index' ? (
          <span>
            <ReportBox>
              <Typographies variant="h5" sx={{ marginBottom: "10px", width: '320px' }}>
                <span style={{ fontWeight: 'bold', color: '#ffe600', textShadow: "#fdaf00 1px 0 10px" }}>جعبه لایتنر</span> وردینا اینجاست
                <p style={{ fontSize: '11px', color: '#838383' }}>اینجا کلمات رو انقدر تمرین می‌کنی تا حسابی تو ذهنت بمونن</p>
              </Typographies>

            </ReportBox>


            <ReportBox style={{ width: '320px' }}>
              <div style={{ display: 'flex', direction: 'rtl', color: "#838383" }}>
                <span style={{ width: '60%' }}>کلمه‌های جعبه‌ی من</span>
                <span style={{ width: '40%', color: '#fff8e8', textShadow: "#fff8e8 1px 0 10px", fontSize: "18px" }}>{unLearnedCount}</span>
              </div>
            </ReportBox>

            <ReportBox style={{ width: '320px', color: "#838383" }}>
              <div style={{ display: 'flex', direction: 'rtl' }}>
                <span style={{ width: '60%' }}>کلمه‌هایی که یاد گرفتم</span>
                <span style={{ width: '40%', color: '#fff8e8', textShadow: "rgb(0, 84, 0) 1px 0 10px", fontSize: "18px" }}>{learnedCount}</span>
              </div>
            </ReportBox>

            {/* Curved Button for Start Learning */}
            {!isEndAmount ? (
              <>

                <GlowingButton variant="contained" onClick={handleStart}>
                  شروع تمرین
                </GlowingButton>
                <GemButton variant="contained" onClick={showEarnPage}>
                  <img
                    src={`/money.webp`}
                    className='gemMotion'
                  />
                  دریافت سکه رایگان
                  <img
                    src={`/Sparkles.webp`}
                    className='gemMotion'
                  />
                </GemButton>
              </>

            ) : (
              <>
              <GlowingButton variant="contained" disabled='true'>
                سکه‌های شما تموم شد
              </GlowingButton>
              <GemButton variant="contained" onClick={showEarnPage}>
                  <img
                    src={`/money.webp`}
                    className='gemMotion'
                  />
                  !دریافت سکه رایگان
                  <img
                    src={`/Sparkles.webp`}
                    className='gemMotion'
                  />
                </GemButton>
              </>
              
            )}
            {/* <FooterMenu></FooterMenu> */}
          </span>


        ) : page == 'training' ? (
          <>
            {!isEndAmount ? (

              <Flashcard

                card={currentWord}
                handleNext={handleNext}
                handleEnd={handleEnd}
                handleRemove={handleRemove}
              />
            ) : (
              <span>
                <ReportBox style={{ width: '320px', color: "#838383" }}>
                  <div style={{ display: 'flex', direction: 'rtl' }}>
                    <span style={{ width: '100%' }}>اعتبار شما پایان یافت!</span>

                  </div>
                </ReportBox>
                <GlowingButton variant="contained" onClick={backToMain}>
                  صفحه اصلی
                </GlowingButton>
              </span>
            )}
          </>
        ) : page == 'earn' ? (
          <EarnPage
            backHome = {backHome}
            devUrl = {devUrl}
            userId = {userId}
            earnTasks = {earnTasks}
            onClick={handleEarn}
            setAmount = {setAmount}
            isDev = {isDev}
          >

          </EarnPage>
        ) : (
          <></>
        )}



      </Box>
    </>
  )


};

export default LeitnerApp;
